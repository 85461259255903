<template>
    <div>
        <div class="wz">
            <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>零工库</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
         <div class="box">
            <div class="serarch">
                <div class="searchleft">
                    <el-form :inline="true" :model="searchform" class="demo-form-inline">
                        <el-form-item label="职位">
                            <el-input v-model="searchform.workName"  placeholder="职位关键词"></el-input>
                        </el-form-item>
                        <el-form-item label="公司">

                            <el-select
                                v-model="searchform.memberId"
                                filterable
                                clearable
                                remote
                                reserve-keyword
                                placeholder="公司"
                                :remote-method="remoteMethod"
                                :loading="loading">
                                <el-option
                                v-for="item in options"
                                :key="item.id"
                                :label="item.userName"
                                :value="item.id">
                                </el-option>
                            </el-select>


                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" icon="el-icon-search"  @click="onSubmit"  >查询</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
            <div class="list" v-loading="loading">
                <el-table
                :data="list"
                border
                :header-cell-style="{'font-weight':'bold','color':'#555555','padding':'6px 0'}"
                stripe
                style="width: 100%">
                    <el-table-column
                    prop="name"
                    width="90"
                    label="名称">
                    </el-table-column>
<!--                    <el-table-column-->
<!--                    prop="idNumber"-->
<!--                    width="260"-->
<!--                    label="身份证号">-->
<!--                    </el-table-column>-->

                    <el-table-column
                    prop="workName"
                    width="130"
                    label="职位">
                    </el-table-column>
                    <el-table-column
                    prop="age"
                    width="100"
                    align="center"
                    label="年龄">
                    </el-table-column>
                    <el-table-column
                    prop="sex"
                    align="center"
                     width="100"
                    label="性别">
                        <template slot-scope="scope">
                            <el-tag v-if="scope.row.sex==1" type="primary" >男</el-tag>
                            <el-tag v-if="scope.row.sex==2" type="primary" >女</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column
                    prop="phone"
                     width="150"
                    label="手机">
                    </el-table-column>
                    <el-table-column
                    prop="weiXinNumber"
                    width="150"
                    label="微信号">
                    </el-table-column>
                    <el-table-column
                    prop="salary"
                    width="150"
                    label="薪资">
                    </el-table-column>
                    <el-table-column
                    prop="workAge"
                    width="150"
                    label="工作年限">
                    </el-table-column>
                    <el-table-column
                    prop="address"
                    label="联系地址">
                    </el-table-column>
                </el-table>
            </div>
            <app-page :total="total" :pageSize="pageSize" :current="pageNo" @handleCurrentChange="currentchange" @handleSizeChange="handchange"></app-page>
         </div>
    </div>
</template>
<script>
import apiurl from '@/api/resume'
import _api from '@/api/index'
export default {
    data(){
        return{
            searchform:{workName:null,memberId:null},
            memberoptions:[],
            list:[],
            total:0,
            pageSize:10,
            pageNo:1,
            loading:false,
            options:[]
        }
    },
    created(){
        this.getList()
       // this.getmember()
    },
    methods:{
        remoteMethod(query) {
        if (query !== '') {
          this.loading = true;
          _api.get(apiurl.queryMemberNotPage,{name:query}).then(res=>{
                this.loading = false;
                this.options = res.data.filter(item => {
                    return item.userName.toLowerCase()
                    .indexOf(query.toLowerCase()) > -1;
                });
            })
        } else {
          this.options = [];
        }
      },
        getmember(){
            _api.get(apiurl.queryMemberNotPage,{}).then(res=>{
                //console.log(res)
                if(res.code==200){
                    this.memberoptions=res.data
                }
            })
        },
        getList(){
            this.loading=true
            _api.post(apiurl.oddJob,{pageNum:this.pageNo,pageSize:this.pageSize,workName:this.searchform.workName,memberId:this.searchform.memberId}).then(res=>{
                console.log(res)
                this.loading=false
                if(res.code==200){
                    this.list=res.data.list
                    this.total=res.data.total
                }
            })
        },
        onSubmit(){
            this.pageNo=1
            this.getList()
        },
        handchange(data){//分页Size变化
            this.pageSize=data
            this.pageNo=1
            this.getList()
        },
        currentchange(data){//当前页变化
            console.log(data)
            this.pageNo=data
            this.getList()
        },
    }
}
</script>